import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';

const Sales = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [salesData, setSalesData] = useState<{
    fecha: string;
    total_estacionamiento: string;
    total_entradas: number;
    total_ventas: string;
    // Agrega más propiedades según sea necesario
  } | null>(null);

  useEffect(() => {
    // Llamada a la API para obtener los datos de ventas
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tickets/sales`);
        const data = await response.json();
        setSalesData(data);
      } catch (error) {
        console.error('Error al obtener los datos de ventas:', error);
      }
    };

    fetchData();
  }, []); // La dependencia vacía asegura que el efecto se ejecute solo una vez al montar el componente

  // Obtener la fecha actual
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <div style={{ flex: 1, padding: '20px' }}>
        <>
          <h2>Fecha: {formattedDate}</h2>
          {salesData && (
            <div>
              <div style={{ marginBottom: '10px' }}>
                <p>Total Estacionamiento: {salesData.total_estacionamiento}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p>Total Entradas: {salesData.total_entradas}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <p>Total Ventas: $ {salesData.total_ventas}</p>
              </div>
              {/* Agrega más detalles según tus necesidades */}
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default Sales;

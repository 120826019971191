// Dashboard.js
import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import TransactionTable from './Tickets/TransactionTable';

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
        <Sidebar />
        <div style={{ flex: 1, padding: '20px' }}>
          <TransactionTable />
        </div>
      </div>
  );
};

export default Dashboard;

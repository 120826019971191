import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageLayout from '../../components/PageLayout/PageLayout';
import PriceCard from '../../components/PriceCards/PriceCard';
import ResidentSwitcher from '../../components/ResidentSwitcher/ResidentSwitcher';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import TitleSection from '../../components/TitleSection/TitleSection';
import Toast from '../../components/Toast/Toast';
import axios from 'axios';

const Prices = (): JSX.Element => {
	const [residentState, setResidentState] = useState<boolean>(false);
	const [prices, setPrices] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
	const apiUrl = process.env.REACT_APP_API_URL;

	const residentHandler = (): void => {
		setResidentState(!residentState);
	};

	useEffect(() => {
        const fetchPrices = async () => {
            try {
                const response = await axios.get(`${apiUrl}/tickets/types`);
                setPrices(response.data.data);
				console.log(response.data.data);
                setIsLoading(false); // Se completa la carga
            } catch (error) {
                setError('Hubo un problema al cargar los precios. Inténtalo de nuevo más tarde.');
                setIsLoading(false); 
            }
        };

        fetchPrices();
    }, [apiUrl]);

	return (
		<PageLayout>
			<SectionLayout>
				<TitleSection
					mainTitle='PRECIOS'
					text='Tarifas Vigentes'
					headerTitle='TIPICUS - PARQUE ACUATICO'
				/>
				<ResidentSwitcher
					label='Soy de la costa?'
					value={residentState}
					onChange={residentHandler}
				/>

				{residentState && (
					<Toast message='Presentando DNI con domicilio en LA COSTA' />
				)}

{isLoading ? (
                <p>Cargando precios...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <PriceContainer>
                   {prices.map((price: any) => (
            price.resident_full_time > 0 && ( // Agregar esta condición
                <PriceCard
                    key={price.id}
                    id={price.id}
                    type={price.type}
                    description={price.description}
                    resident_full_time={price.resident_full_time} // O la propiedad correspondiente
                    resident_part_time={price.resident_part_time} // O la propiedad correspondiente
                    non_resident_full_time={price.non_resident_full_time}
                    non_resident_part_time={price.non_resident_part_time}
                    residentState={residentState}
                />
            )
        ))}
                </PriceContainer>
            )}
		
			</SectionLayout>
			<SectionLayout>
				<TitleSection
					mainTitle='JORNADAS'
					headerTitle='TIPICUS - PARQUE ACUATICO'
					text='Horarios'
				/>

				<ServiceCard
					title='Jornada Completa'
					text='11:00hs a 19:00hs.' id={0}				/>
				<ServiceCard title='Media jornada' text='15:00hs a 19:00hs.' id={0} />
			</SectionLayout>
		</PageLayout>
	);
};

export default Prices;

const PriceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.md};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: row;
		justify-content: space-between;
		padding-top: ${({ theme }) => theme.spacing.lg};
	}
`;



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import PageLayout from '../../components/PageLayout/PageLayout';
import TitleSection from '../../components/TitleSection/TitleSection';

const Success = () => {
  const [message, setMessage] = useState('Cargando...');
  useEffect(() => {
    // Extraer parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const paymentStatus = urlParams.get('payment_status');
    const preferenceId = urlParams.get('preference_id');

    // Mostrar mensaje en función del estado de pago
    if (paymentStatus === '1') {
      setMessage('¡Compra exitosa! Gracias por tu compra.');
      
      setTimeout(() => {
        window.location.href = '/'; // Reemplaza '/otra-ruta' con la ruta a la que deseas redirigir
      }, 5000);
    } else {
      setMessage('Error en la transacción. Por favor, intenta nuevamente  .');
    }
  }, []);

  return (
    <PageLayout>
			<SectionLayout>
            <TitleSection
					mainTitle='TIPICUS - PARQUE ACUATICO'
					text=''
					headerTitle=''
				/>
    <div style={{display:'flex', alignItems:'center' }}>
      <h2>{message}</h2>
      <h1>Recibiras un correo electronico con el detalle de la compra.</h1>
    </div>
                
            </SectionLayout>
    </PageLayout>

  );
};

export default Success;

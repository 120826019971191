import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTicketContext } from './TicketContext';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
    }
`;

const Label = styled.label`
    flex: 1;
    display: flex;
    flex-direction: column;

    span {
        color: red;
        font-size: 12px;
        margin-top: 4px;
    }

    @media screen and (min-width: 768px) {
        flex-basis: 50%;
    }
`;

const Input = styled.input`
    padding: 8px;
    width: 100%;
    margin-bottom: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }
`;

interface PersonalDataProps {
    onFormValidityChange: (valid: boolean) => void; // Función para notificar la validez del formulario
}

const PersonalData: React.FC<PersonalDataProps> = ({ onFormValidityChange }) => {
    const { personalData, setPersonalData } = useTicketContext();
    const [errors, setErrors] = useState({
        surname: '',
        name: '',
        identificationNumber: '',
        email: '',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        const value = event.target.value;

        let error = '';
        switch (fieldName) {
            // ... (otras cases)
            default:
                break;
        }

        setErrors({ ...errors, [fieldName]: error });

        setPersonalData({
            ...personalData,
            [fieldName]: value,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Verificar si hay errores antes de enviar los datos
        const hasErrors = Object.values(errors).some((error) => error !== '');

        if (hasErrors) {
            alert('Por favor, complete todos los campos correctamente.');
            return;
        }

        const isFormValid = true;
        onFormValidityChange(isFormValid);
    };


    useEffect(() => {
        const isFormValid = Object.values(errors).every(error => error === '');
        onFormValidityChange(isFormValid);
    }, [errors, onFormValidityChange]);

    return (
         <>
            <div style={{ marginBottom: 20 }}>
                <p style={{ fontSize: 12 }}>Los datos a completar están relacionados a su compra, y se le solicitarán para hacer uso de la misma. Verifique que sean correctos antes de finalizar la compra, para evitar inconvenientes.</p>
            </div>
            <Form onSubmit={handleSubmit}>
                <Label>
                    Apellido:
                    <Input type="text" value={personalData.surname} onChange={(e) => handleInputChange(e, 'surname')} />
                    {errors.surname && <span>{errors.surname}</span>}
                </Label>
                <Label>
                    Nombre:
                    <Input type="text" value={personalData.name} onChange={(e) => handleInputChange(e, 'name')} />
                    {errors.name && <span>{errors.name}</span>}
                </Label>
                <Label>
                    DNI:
                    <Input type="text" value={personalData.identificationNumber} onChange={(e) => handleInputChange(e, 'identificationNumber')} />
                    {errors.identificationNumber && <span>{errors.identificationNumber}</span>}
                </Label>
                <Label>
                    Email:
                    <Input type="text" value={personalData.email} onChange={(e) => handleInputChange(e, 'email')} />
                    {errors.email && <span>{errors.email}</span>}
                </Label>
            </Form>
        </>
    );
};

export default PersonalData;

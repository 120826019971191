import React from 'react';
import Step1 from './Step1'; // Importa los componentes de los pasos necesarios
import Step2 from './Step2';

import { TicketProvider } from './TicketContext';
import Step3 from './Step3';

interface WizardProps {
    currentStep: number;
    onNextStep: () => void;
    onPreviousStep: () => void;
}

const Wizard: React.FC<WizardProps> = ({ currentStep, onNextStep, onPreviousStep  }) => {
    const handleNextStep = () => {
        onNextStep();
    };

    const handlePreviousStep = () => {
        onPreviousStep();
    };

    const handlePersonalDataSubmit = () => {
        handleNextStep();
    };

    return (
        <TicketProvider> {/* Inicializa el contexto una sola vez alrededor de todo el Wizard */}
            {currentStep === 1 && <Step1 onNext={onNextStep} />}
            {currentStep === 2 && <Step2 onNext={handlePersonalDataSubmit} onPrevious={handlePreviousStep} />}
            {currentStep === 3 && <Step3 onPrevious={onPreviousStep} />}
        </TicketProvider>
    );
};

export default Wizard;

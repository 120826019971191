// Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div style={{ width: '200px', backgroundColor: '#333', padding: '20px', height: '100%' }}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>
          <Link to="/dashboard" style={linkStyle}>Inicio</Link>
        </li>
        <li>
          <Link to="/dashboard/sales" style={linkStyle}>Ventas</Link>
        </li>
        <li>
          <Link to="/dashboard/prices" style={linkStyle}>Precios</Link>
        </li>
        <li>
          <Link to="/login" style={linkStyle}>Cerrar Sesión</Link>
        </li>
        {/* Añade más enlaces según sea necesario */}
      </ul>
    </div>
  );
};

const linkStyle = {
  textDecoration: 'none',
  color: '#fff',
  padding: '10px',
  display: 'block',
  borderRadius: '5px',
  margin: '5px 0',
  backgroundColor: '#555',
  transition: 'background-color 0.3s ease',
};

export default Sidebar;

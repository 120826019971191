import React, { useState } from 'react';

import TicketSales from './TicketSales';
import styled from 'styled-components';

interface Step1Props {
    onNext: () => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
    const [isEntrySelected, setIsEntrySelected] = useState(false);

    const handleNext = () => {
        if (isEntrySelected) {
            onNext();
        } else {
            //alert('Debes seleccionar al menos una entrada.');
            // Puedes mostrar un mensaje de error o realizar alguna otra acción para notificar al usuario.
        }
    };

    const handleEntrySelected = (isSelected: boolean) => {
        setIsEntrySelected(isSelected);
    };

    return (
        <div>
            <h2>Selecciona tus entradas</h2>
            <TicketSales onEntrySelected={handleEntrySelected} />
            <NextButton onClick={handleNext}>Siguiente</NextButton>
        </div>
    );
};

export default Step1;

const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    width: 200px; /* Ancho máximo para pantallas mayores a 768px */
  }

`;

// TransactionTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Transaction from '../../../constants/transaction';
import { Link } from 'react-router-dom';

const TransactionTable = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const fetchData = async () => {
    try {
      const response = await axios.get<Transaction[]>(`${apiUrl}/tickets`);
      setTransactions(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getValidationStyle = (validationStatus: number) => {
    // Define estilos, incluyendo padding y bordes redondeados, solo para la palabra dentro de la celda
    return validationStatus === 1
      ? { backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px' }
      : { backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' };
  };

  const handleButtonClick = async (validationCode: string) => {
    try {
      // Llama a la API utilizando el validation_code
      const response = await axios.post(`${apiUrl}/tickets/validate`, { code: validationCode });
      console.log('API Response:', response.data);

      fetchData();
      // Puedes realizar acciones adicionales después de la validación si es necesario
    } catch (error) {
      console.error('Error calling validation API:', error);
    }
  };

  const handleCancelButtonClick = async (validationCode: string) => {
    try {
      // Llama a la API utilizando el validation_code
      const response = await axios.post(`${apiUrl}/tickets/cancel`, { code: validationCode });
      console.log('API Response:', response.data);

      fetchData();
      // Puedes realizar acciones adicionales después de la validación si es necesario
    } catch (error) {
      console.error('Error calling validation API:', error);
    }
  };

  return (
    <div>
      <h1>Lista de Transacciones</h1>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ background: '#f2f2f2', height: '40px'  }}>
            <th>ID</th>
            <th>Fecha de Compra</th>
            <th>Cliente</th>
            <th>Total Amount</th>
            <th>Nro Transacción</th>
            <th>Código de Validación</th>
            <th>Estado de Validación</th>
            <th>Fecha de Validación</th>
            <th>Email del Cliente</th>
            <th>DNI</th>
            <th>Entradas</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id} style={{ borderBottom: '1px solid #ddd', height: '40px'  }}>
              <td>{transaction.id}</td>
              <td>{transaction.purchase_date}</td>
              <td>{transaction.customer.name}, {transaction.customer.surname}</td>
              <td style={{textAlign: 'right'}}>$ {transaction.total_amount}</td>          
              <td style={{textAlign: 'right'}}>{transaction.transaction_number}</td>
              <td style={{textAlign: 'right'}}>
                <Link to={`/dashboard/ticket-details/${transaction.validation_code}`}>
                  {transaction.validation_code}
                </Link>
              </td>
              <td style={{textAlign: 'center'}}>
              <span style={getValidationStyle(transaction.validation_status)}>
                  {transaction.validation_status === 1 ? 'Validado' : 'No Validado'}
                </span>

              </td>
              <td>{transaction.validation_date || ''}</td>
              <td>{transaction.customer.email}</td>
              <td>{transaction.customer.identification_number}</td>
              <td style={{textAlign: 'right'}}>{transaction.ticket_details_count}</td>
              <td style={{ textAlign: 'right' }}>
                <button onClick={() => handleButtonClick(transaction.validation_code)}>
                  Validar
                </button>
              </td>
              <td style={{ textAlign: 'right' }}>
                <button onClick={() => handleCancelButtonClick(transaction.validation_code)}>
                  Cancelar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
function fetchData() {
  throw new Error('Function not implemented.');
}

